const config = {
  // BASE_URL: process.env.REACT_APP_ISDEV
  //   ? 'https://simpeldesapi.loginusa.id/main/'
  //   : 'https://api.desapedia.co.id/main/',
  BASE_URL_IMAGE: process.env.REACT_APP_ISDEV
    ? 'https://simpeldesapi.loginusa.id/fileapi/'
    : 'https://api.desapedia.co.id/fileapi/',
  BASE_URL: process.env.REACT_APP_ISDEV === "true"
    ? 'https://devapigateway.desapedia.co.id/v1/main/'
    :  'https://apigateway.desapedia.co.id/v1/main/',
};

export { config };

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "./DetailArtikel.scss";
import { Link, useParams } from 'react-router-dom';
import FeatherIcon from "feather-icons-react";
import { config } from "../../config";
import { Spin } from 'antd';
import LoadingOverlay from '../../component/LoadingOverlay';

const DetailArtikel = () => {
  const { id } = useParams(); // Extract `id` from the URL
  const [article, setArticle] = useState(null); // State to hold article data
  const [loading, setLoading] = useState(true); // State to manage loading state
  const [error, setError] = useState(null); // State to manage errors

  // Fetch article data from the API
  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`${config.BASE_URL}berita/findOne/${id}`);
        setArticle(response.data.data); // Populate article state with API response
        
        setLoading(false);
      } catch (err) {
        console.error("Error fetching article:", err);
        setError("Failed to load article.");
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <LoadingOverlay />
        <p style={{ color: '#F1582E', marginTop: '10px', fontWeight: 'bold' }}>
          Harap Tunggu Sesaat..
        </p>
      </div>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <section id="DetailArtikel">
      <div className="navbar">
        <Link to={"/"} className="link-back">
          <FeatherIcon icon="arrow-left" />
          Kembali
        </Link>
      </div>
      <div className="container">
        <div className="news-content">
          <h2>{article.judul}</h2>
          <p className="news-date">{new Date(article.tanggalRelease).toLocaleString()}</p>
          <img 
            src={
              article.foto 
                ? `${config.BASE_URL}getFile?pathFile=${article.foto}` 
                : "default-image-path.png"
            } 
            alt="News" 
          />
          <p
            dangerouslySetInnerHTML={{
            __html: article.isi.substring(0, 150) + '...',
          }}
          />
          {article.createdName && (
            <p className="created-by">
              Dibuat oleh: <strong>{article.createdName}</strong>
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default DetailArtikel;

import React from 'react';
import { Spinner } from 'reactstrap';
import { Typography } from 'antd';

const { Text } = Typography;

const LoadingOverlay = () => {
  return (
    <div style={styles.overlay}>
      <Spinner style={styles.spinner} color="light" />
      <Text style={styles.text}>Harap Tunggu Sesaat...</Text>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#F1582E',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 1050,
  },
  spinner: {
    width: '4rem',
    height: '4rem',
    marginBottom: '2rem',
  },
  text: {
    color: 'white',
    fontSize: '1.2rem',
    fontWeight: 'semibold',
  },
};

export default LoadingOverlay;

import React, { Component, Suspense } from 'react';
import { config } from '../../config'
import axios from 'axios'
import LoadingOverlay from '../../component/LoadingOverlay';

// Importing Section
const Navbar = React.lazy(() => import('../../component/Navbar/NavBar'));
const Carousel = React.lazy(() => import('../../component/Carousel'));
// const Sejarah = React.lazy(() => import('../../component/Sejarah'));
const VisiMisi = React.lazy(() => import('../../component/VisiMisi'));
const Penduduk = React.lazy(() => import('../../component/Penduduk'));
const Dusun = React.lazy(() => import('../../component/Dusun'));
const Footer = React.lazy(() => import('../../component/Footer/Footer'));
const Artikel = React.lazy(() => import('../../component/Artikel'));
const DesaKami = React.lazy(() => import('../../component/DesaKami'));
const PetaDesa = React.lazy(() => import('../../component/PetaDesa'));

const AparaturDesa = React.lazy(() => import('../../component/AparaturDesa'));
const Pariwisata = React.lazy(() => import('../../component/Pariwisata'));
const Galeri = React.lazy(() => import('../../component/Galeri'));
const Komunitas = React.lazy(() => import('../../component/Komunitas'));
const Fasilitas = React.lazy(() => import('../../component/Fasilitas'));
const Layanan = React.lazy(() => import('../../component/Layanan'));
const Struktur = React.lazy(() => import('../../component/Struktur'));
const Komoditas = React.lazy(() => import('../../component/Komoditas'));

class Layout1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      navItems: [
        { id: 1, idnm: 'beranda', navheading: 'Beranda' },
        // { id: 2, idnm: 'sejarah', navheading: 'Sejarah' },
        { id: 3, idnm: 'visi-misi', navheading: 'Visi Misi' },
        { id: 4, idnm: 'penduduk', navheading: 'Penduduk' },
        { id: 5, idnm: 'dusun', navheading: 'Dusun' },
        { id: 6, idnm: 'article', navheading: 'Artikel' },
        { id: 7, idnm: 'DesaKami', navheading: 'Desa Kami' },
        { id: 8, idnm: 'PetaDesa', navheading: 'Peta' },
        { id: 10, idnm: 'galery', navheading: 'Galeri' },
        { id: 11, idnm: 'struktur', navheading: 'Struktur' },
        { id: 12, idnm: 'aparatur-desa', navheading: 'Aparatur Desa' },
        // { id: 12, idnm: 'pariwisata', navheading: 'Pariwisata' },
        { id: 13, idnm: 'fasilitas', navheading: 'Fasilitas' },
        // { id: 14, idnm: 'komunitas', navheading: 'Komunitas' },
        { id: 15, idnm: 'komoditas', navheading: 'Komoditas' },
        { id: 16, idnm: 'layanan', navheading: 'Layanan Desa' },
      ],
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: '',
      carouselItems: [],
      dataDusun: [],
      aparaturData: [],
      articles: [],
      fasilitas: [],
      komoditas: [],
      kegiatan: [],
      layanan: [],
      footerData: {},
      videoUrl: '',
      petaUrl: '',
      strukturDesa: '',
      nama: '',
      visi: '',
      misi: '',
      dataPenduduk: {
        totalPenduduk: 0,
        lakiLaki: 0,
        perempuan: 0,
      },
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollNavigation, true);
    this.fetchData();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }

  changeFavicon = (faviconUrl) => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    link.href = faviconUrl;
  };

  fetchData = async () => {
    this.setState({ loading: true });
    const namaDomain = window.location.href;
    
    try {
      const response = await axios.get(`${config.BASE_URL}organization/findContentOrganization?namaDomain=${namaDomain}`);
      
      if (response.data.status) {
        const { berita, organization, dusun, carousel, jumahLakiLaki, jumahPerempuan, aparaturOrganizations, struktural, fasilitas, komoditas, kegiatan, layanan } = response.data.data;
        
        // Tab Organization
        if (organization) {
          document.title = organization.nama;
          if (organization.logo) {
            const faviconUrl = `${config.BASE_URL}getFile?pathFile=${organization.logo}`;
            this.changeFavicon(faviconUrl);
          }
        }
        // Transform berita
        const articles = berita?.map((item) => ({
          beritaId: item.beritaId,
          judul: item.judul || 'Untitled Article',
          isi: item.isi || '-',
          created: item.tanggalRelease || item.created,
          foto: item.foto ? `${config.BASE_URL}getFile?pathFile=${item.foto}` : '',
        })) || [];

        // Transform aparatur
        const aparaturData = aparaturOrganizations?.map(item => ({
          img: `${config.BASE_URL}getFile?pathFile=${item.foto}`,
          nama: item.nama,
          jabatan: item.jabatan,
          quotes: item.quotes,
        })) || [];

        // Transform komoditas
        const transformedKomoditas = komoditas?.map((item, index) => ({
          komoditasId: item.komoditasId || index,
          created: item.created || '',
          nama: item.nama || 'Unknown Name',
          keterangan: item.keterangan || 'No Description',
          foto: item.foto ? `${config.BASE_URL}getFile?pathFile=${item.foto}` : '',
        })) || [];
        
        // Ambil Struktur Desa
        let strukturDesa = '';
        if (struktural && Array.isArray(struktural)) {
          const strukturalFoto = struktural[0];
          if (strukturalFoto && strukturalFoto.foto) {
            strukturDesa = `${config.BASE_URL}getFile?pathFile=${strukturalFoto.foto}`;
          }
        }

        // Transform Fasilitas
        const formattedFasilitas = fasilitas?.map((item) => {
          const fotos = item.fasilitasDetail?.map((detail) =>
            detail.foto ? `${config.BASE_URL}getFile?pathFile=${detail.foto}` : ''
          ) || [];
          return { ...item, foto: fotos };
        }) || [];

        // Transform Kegiatan (Galeri)
        const formattedKegiatan = kegiatan?.map((item) => {
          const fotos = item.kegiatanDetail?.map((detail) =>
            detail.foto ? `${config.BASE_URL}getFile?pathFile=${detail.foto}` : ''
          ) || [];
          return { ...item, foto: fotos };
        }) || [];

        // Format layanan
        const formattedLayanan = layanan?.map((item) => ({
          nama: item.nama || '',
          created: item.created,
          createdBy: item.createdBy,
          layananId: item.layananId,
        })) || [];

        // Format data footer
        const footerData = {
          alamat: organization.alamat || '',
          contactNumber: organization.contactNumber || '',
          logo: organization.logo ? `${config.BASE_URL}getFile?pathFile=${organization.logo}` : '',
        };
        

        this.setState({
          nama: organization.nama,
          visi: organization.visi,
          misi: organization.misi,
          videoUrl: organization.urlVideo ? `https://www.youtube.com/embed/${organization.urlVideo}?autoplay=1` : '',
          petaUrl: organization.peta,
          articles,
          footerData,
          dataPenduduk: {
            totalPenduduk: (jumahLakiLaki || 0) + (jumahPerempuan || 0),
            lakiLaki: jumahLakiLaki || 0,
            perempuan: jumahPerempuan || 0,
          },
          dataDusun: dusun?.map((item, index) => ({
            nama: item.nama || `Dusun ${index + 1}`,
            jumlah: item.jumlahWarga || '-',
            created: item.created,
            createdBy: item.createdBy,
            mdusunId: item.mdusunId,
          })) || [],
          carouselItems: carousel?.map((item, index) => ({
            carouselId: item.carouselId || `carousel-${index}`,
            deskripsi: item.deskripsi,
            image: item.image ? `${config.BASE_URL}getFile?pathFile=${item.image}` : '',
            backgroundImage: item.backgroundImage ? `${config.BASE_URL}getFile?pathFile=${item.backgroundImage}` : '',
          })) || [],
          aparaturData,
          strukturDesa,
          fasilitas: formattedFasilitas,
          komoditas: transformedKomoditas,
          kegiatan: formattedKegiatan,
          layanan: formattedLayanan,
          loading: false,
        });
      } else {
        this.setState({ loading: false, error: 'Failed to load data' });
      }
    } catch (error) {
      this.setState({ loading: false, error: 'Failed to load data' });
    }
  };

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: 'nav-sticky', imglight: false });
    } else {
      this.setState({ navClass: '', imglight: false });
    }
  };

  //set preloader div
  // PreLoader = () => {
  //   return (
  //     <div id='preloader'>
  //       <div id='status'>
          // <div className='spinner'>
          //   <div className='bounce1'></div>
          //   <div className='bounce2'></div>
          //   <div className='bounce3'></div>
          // </div>
  //       </div>
  //     </div>
  //   );
  // };

  render() {
    return (
      <React.Fragment>
        {/* <Suspense fallback={this.PreLoader()}> */}
        <Suspense fallback={<LoadingOverlay />}>
          {/* Importing Navbar */}
          <Navbar
            navItems={this.state.navItems}
            navClass={this.state.navClass}
            imglight={this.state.imglight}
          />

          {/* Carousel */}
          <Carousel carouselItems={this.state.carouselItems} />

          {/* Sejarah */}
          {/* <Sejarah /> */}

          {/* Visi Misi */}
          <VisiMisi visi={this.state.visi} misi={this.state.misi} loading={this.state.loading} />

          {/* Penduduk */}
          <Penduduk dataPenduduk={this.state.dataPenduduk} />

          {/* Dusun */}
          <Dusun dataDusun={this.state.dataDusun} />

          {/* Artikel */}
          <Artikel articles={this.state.articles} />

          {/* Desa Kami */}
          <DesaKami videoUrl={this.state.videoUrl} />

          {/* Peta */}
          <PetaDesa petaUrl={this.state.petaUrl} />

          {/* Galeri */}
          <Galeri kegiatan={this.state.kegiatan} />

          {/* Struktur */}
          <Struktur strukturDesa={this.state.strukturDesa} />

          {/* Aparatur Desa */}
          <AparaturDesa 
            aparaturData={this.state.aparaturData} 
            fetchAparaturData={this.fetchAparaturData} 
            isLoading={this.state.isLoading} 
            error={this.state.error} 
          />

          {/* Parawisata */}
          {/* <Pariwisata /> */}

          {/* Fasilitas */}
          <Fasilitas fasilitas={this.state.fasilitas} />

          {/* Komunitas */}
          {/* <Komunitas /> */}

          {/* Komoditas */}
          <Komoditas komoditas={this.state.komoditas} />

          {/* Layana Desa */}
          <Layanan layanan={this.state.layanan} />

          {/* Importing Footer */}
          <Footer footerData={this.state.footerData} />
        </Suspense>
      </React.Fragment>
    );
  }
}
export default Layout1;
